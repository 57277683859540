import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as d3 from 'd3';
import {
  AbstractComponent,
  PositionOverlay,
  WithOffset,
  LineChartModel
} from 'src/app/shared';
import { ILineChartTooltipNode } from './d3-line-chart';
@Component({
  selector: 'd3-line-chart',
  templateUrl: './d3-line-chart.component.html',
  styleUrls: ['./d3-line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class D3LineChartComponent extends AbstractComponent {
  @ViewChild('my_dataviz', { static: true }) chartContainer: ElementRef;
  @Input('lineChartData') public lineChartData: LineChartModel[] = [];
  @Input('xLabels') public xLabels: number[] = [];
  @Input('marginTop') public marginTop: number;
  @Input('pointSize') public pointSize: number;
  @Input('lineWidth') public lineWidth: number;
  @Input('marginLeft') public marginLeft: number;
  @Input('axisSpacing') public axisSpacing: number;
  @Input('marginRight') public marginRight: number;
  @Input('marginBottom') public marginBottom: number;
  @Input('axisFontSize') public axisFontSize: number;
  @Input('relativeWidth') public relativeWidth: number;
  @Input('relativeHeight') public relativeHeight: number;
  @Input('isDrawEndLines') public isDrawEndLines: boolean = false;
  @Input('tooltipService') public tooltipService: any;
  @Input('maxYValue') public maxYValue: any;

  protected dataAdaptedToD3Js = [];
  protected dataToDrawChart = [];
  public myColor = {};
  public width;
  public height;
  public svg;
  public x;
  public y;
  public chartColor = '#6b778c';
  item: any;

  constructor(
    // protected tooltip: ProjectReportLineChartTooltipService,
    protected _cdr: ChangeDetectorRef
  ) {
    super();
  }

  afterViewInit(): void {
    if (this.lineChartData?.length > 0) {
      this.adaptData();
      setTimeout(() => {
        this.draw();
      }, 0);
    }
  }

  changes(changes: SimpleChanges): void {
    if (changes['lineChartData'] || changes['xLabels']) {
      if (this.lineChartData?.length > 0) {
        this.adaptData();
        setTimeout(() => {
          this.draw();
        }, 0);
      }
    }
  }

  adaptData() {
    const adaptedData = [];

    this.lineChartData?.[0].data.forEach((element, index) => {
      const dataPoint = { label: this.xLabels[index] };
      this.lineChartData.forEach((chartData) => {
        dataPoint[chartData.label] = chartData.data[index];
      });
      adaptedData.push(dataPoint);
    });

    this.dataAdaptedToD3Js = adaptedData;
  }

  draw() {
    // clear current SVG
    d3.select('#my_dataviz').selectAll('*').remove();

    // set the dimensions and margins of the graph
    const margin = {
      top: this.marginTop,
      right: this.marginRight,
      bottom: this.marginBottom,
      left: this.marginLeft
    };
    (this.width = this.relativeWidth - this.marginLeft - this.marginRight),
      (this.height = this.relativeHeight - this.marginTop - this.marginBottom);

    this.appendSvg(margin);
    this.formatData();
    this.drawXAxis();
    this.drawYAxis();
    this.isDrawEndLines && this.drawEndLines();
    this.drawLine();
    this.drawPoint();

    this._cdr.markForCheck();
  }

  appendSvg(margin) {
    // append the svg object to the body of the page
    this.svg = d3
      .select('#my_dataviz')
      .append('svg')
      .attr(
        'viewBox',
        `0 0 ${this.width + margin.left + margin.right} ${
          this.height + margin.top + margin.bottom
        }`
      )
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
  }

  formatData() {
    // List of groups
    const allGroup = [];
    for (let i = 0; i < this.lineChartData.length; i++) {
      allGroup.push(this.lineChartData[i].label);
      this.myColor[this.lineChartData[i].label] = this.lineChartData[i].color;
    }

    // Reformat the data: we need an array of arrays of {x, y} tuples
    const totalArray = allGroup.map((grpName) => {
      return {
        name: grpName,
        value: this.dataAdaptedToD3Js.reduce(
          (value, d) => value + (d[grpName] !== null ? +d[grpName] : 0),
          0
        )
      };
    });

    this.dataToDrawChart = allGroup.map((grpName) => {
      return {
        name: grpName,
        values: this.dataAdaptedToD3Js.map((d, index) => ({
          label: index,
          value: d[grpName] !== null ? +d[grpName] : null,
          labelText: d.label,
          totalValue: totalArray.find((e) => e.name === grpName).value
        }))
      };
    });
  }

  drawXAxis() {
    this.x = d3
      .scaleLinear()
      .domain([0, this.xLabels.length - 1]) // Cập nhật miền của trục X
      .range([0, this.width]);

    const xaxis = d3.axisBottom(this.x).tickFormat((d) => this.xLabels[d]);

    // Show đủ tick nếu số điểm < 15
    if (this.xLabels.length <= 15) {
      xaxis.ticks(this.xLabels.length);
    }

    this.svg
      .append('g')
      .attr('transform', `translate(0, ${this.height + this.axisSpacing})`) // Dời trục X xuống
      .call(xaxis)
      .select('.domain')
      .style('stroke', this.chartColor) // Thay đổi màu của đường trục X
      .style('color', this.chartColor);

    this.svg
      .selectAll('.tick text')
      .style('fill', this.chartColor) // Thay đổi màu của nhãn text trên trục X
      .style('font-family', 'Lato') // Set the font family to Lato
      .style('font-size', this.axisFontSize);

    this.svg
      .selectAll('.tick line')
      .style('stroke', this.chartColor) // Thay đổi màu của đường trục X
      .style('color', this.chartColor);
  }

  drawYAxis() {
    // Add Y axis
    const maxYValue = this.lineChartData.reduce(
      (max, d) => (Math.max(...d.data) > max ? Math.max(...d.data) : max),
      0
    );

    const maxYAxisValue = this.maxYValue ? this.maxYValue : Math.ceil((maxYValue * 1.2) / 10) * 10 || 10 ;
    this.y = d3
      .scaleLinear()
      .domain([0, maxYAxisValue])
      .range([this.height, 0]);

    this.svg
      .append('g')
      .attr('transform', `translate(${-this.axisSpacing}, 0 )`) // Dời trục y sang trái
      .call(d3.axisLeft(this.y).ticks(3).tickSize(0))
      .select('.domain')
      .remove();

    this.svg
      .selectAll('.tick text')
      .style('font-family', 'Lato')
      .style('font-size', this.axisFontSize); // Set the font family to Lato

    this.svg
      .selectAll('line.horizontalGrid')
      .data([0, maxYAxisValue / 2, maxYAxisValue])
      .enter()
      .append('line')
      .attr('class', 'horizontalGrid')
      .attr('x1', 0)
      .attr('x2', this.width)
      .attr('y1', (d) => this.y(d))
      .attr('y2', (d) => this.y(d))
      .attr('stroke', '#e6edf1')
      .attr('stroke-width', this.lineWidth);
  }

  drawEndLines() {
    this.dataToDrawChart.map((line) => {
      const lineEndPointPosition =
        line.values.filter((item) => item.value != null).length - 1;

      const lineEndPosition = this.x(lineEndPointPosition);

      if (lineEndPointPosition == this.xLabels.length - 1) return;

      this.svg
        .append('line')
        .attr('x1', lineEndPosition)
        .attr('x2', lineEndPosition)
        .attr('y1', -this.axisSpacing)
        .attr('y2', this.height + this.axisSpacing)
        .attr('stroke', this.myColor[line.name])
        .attr('stroke-width', this.lineWidth);
    });
  }
  drawLine() {
    // Add the lines
    let line = d3
      .line()
      .defined((d) => d['value'] !== null) // Chỉ vẽ các điểm không phải null
      .x((d) => {
        return this.x(d['label']);
      })
      .y((d) => {
        return this.y(+d['value']);
      });

    this.svg
      .selectAll('myLines')
      .data(this.dataToDrawChart)
      .enter()
      .append('path')
      .attr('class', (d) => {
        return d.name;
      })
      .attr('d', (d: any) => {
        return line(d.values);
      })
      .attr('stroke', (d) => {
        return this.myColor[d.name];
      })
      .style('stroke-width', this.lineWidth)
      .style('fill', 'none');
  }

  drawPoint() {
    this.svg
      .selectAll('myDots')
      .data(this.dataToDrawChart)
      .enter()
      .append('g')
      .style('fill', (d) => {
        return this.myColor[d.name];
      })
      .attr('class', (d) => {
        return d.name;
      })
      .selectAll('myPoints')
      .data((d) => d.values.filter((v) => v.value !== null))
      .attr('class', 'myCircle')
      .enter()
      .append('circle')
      .attr('cx', (d) => {
        return this.x(d.label);
      })
      .attr('cy', (d) => {
        return this.y(d.value);
      })
      .attr('r', this.pointSize)
      .attr('stroke', 'white')
      .on('mouseover', this.mouseOver) // Pass the line data
      .on('mouseleave', this.mouseLeave);
  }

  showTooltip(event: MouseEvent, d) {
    if (!this.isWebLayout) {
      return;
    }
    const element = document.querySelector('#my_dataviz') as HTMLElement;
    const position = this.calculateTooltipPositions(element, d);

    const tooltipPosition = [
      WithOffset(PositionOverlay.topLeft, position.x, position.y)
    ];

    const tooltipValue: ILineChartTooltipNode = {
      value: d.value,
      total: d.totalValue
    };
    this.tooltipService?.open(element, tooltipValue, tooltipPosition);

    if (this.tooltipService?.isOpen) {
      this.tooltipService?.updatePosition(tooltipPosition);
    }
  }

  calculateTooltipPositions(element: HTMLElement, d) {
    const padding = 14;

    const absoluteWidth = element.offsetWidth - 2 * padding;
    const absoluteHeight = element.offsetHeight - 2 * padding;

    const relativeWidth = this.width + this.marginLeft + this.marginRight;
    const relativeHeight = this.height + this.marginBottom + this.marginTop;

    const widthRatio = absoluteWidth / relativeWidth;
    const heightRatio = absoluteHeight / relativeHeight;

    const xPosition = this.x(d.label) * widthRatio;
    const yPosition = this.y(d.value) * heightRatio;

    return {
      x: xPosition,
      y: yPosition
    };
  }
  hideTooltip() {
    this.tooltipService?.hide();
  }

  mouseOver = (event, d) => {
    this.svg
      .append('line')
      .attr('class', 'hover-line') // Class name for easier selection/removal
      .attr('x1', 0) // Start at the leftmost part of the chart
      .attr('x2', this.width) // End at the rightmost part of the chart
      .attr('y1', this.y(d.value)) // Vertical position same as the hovered point
      .attr('y2', this.y(d.value))
      .style('stroke', 'lightgrey') // Line color
      .style('stroke-width', this.lineWidth) // Line thickness
      .style('stroke-dasharray', '4, 4');

    d3.select(event.target)
      .attr('r', this.pointSize + 3) // Increase the radius of the circle
      .style('stroke', 'white') // Add a white stroke around the point
      .style('stroke-width', 4) // Make the stroke thicker
      .style('fill-opacity', 1) // Ensure the point is fully opaque
      .style('filter', 'drop-shadow(0 0 5px #aaa)');

    this.drawLine();
    this.drawPoint();

    this.showTooltip(event, d);
    this._cdr.markForCheck();
  };

  mouseLeave = (event, d) => {
    d3.select(event.target)
      .attr('r', this.pointSize)
      .style('stroke-width', 1) // Reset the radius
      .style('filter', 'none');
    this.svg.selectAll('.hover-line').remove();
    this.hideTooltip();

    this._cdr.markForCheck();
  };
}
